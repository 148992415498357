<template>
    <el-dialog v-model="visible" :title="editorForm.id ? '编辑' : '新增'" width="500px" :before-close="BeforeClose">
        <el-form :model="editorForm" :rules="rulesForm" ref="ruleFormRef">
            <el-form-item label="标题" prop="title">
                <el-input v-model="editorForm.title"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="content">
                <div class="choosable">
                    <div class="item">
                        <span>A：</span>
                        <el-input type="textarea" v-model="choosable.A"></el-input>
                    </div>
                    <div class="item">
                        <span>B：</span>
                        <el-input type="textarea" v-model="choosable.B"></el-input>
                    </div>
                    <div class="item">
                        <span>C：</span>
                        <el-input type="textarea" v-model="choosable.C"></el-input>
                    </div>
                    <div class="item">
                        <span>D：</span>
                        <el-input type="textarea" v-model="choosable.D"></el-input>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="答案" prop="result">
                <el-radio-group v-model="editorForm.result">
                    <el-radio label="A">A</el-radio>
                    <el-radio label="B">B</el-radio>
                    <el-radio label="C">C</el-radio>
                    <el-radio label="D">C</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="解析" prop="analysis">
                <el-input type="textarea" v-model="editorForm.analysis"></el-input>
            </el-form-item>
        </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="EditorSubmit(ruleFormRef)">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { inject, reactive, ref, watch } from 'vue'

import { QuestionAdd, QuestionEdit } from '@/api/answer'

export default {
    props: {
        title: {
            type: String,
            default: '新增商品'
        }
    },
    emits: ['submit'],
    setup(props, ctx) {
        const elplus = inject('elplus')
        const rulesForm = reactive({
            title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
            content: [{ required: true, message: '请填写内容', trigger: 'change' }],
            result: [{ required: true, message: '请填写答案', trigger: 'change' }],
            analysis: [{ required: true, message: '请填写解析', trigger: 'change' }],
        })

        let choosable = ref({A: '', B: '', C: '', D: ''})

        let visible = inject('answerVisible')
        let editorForm = inject('editorForm')
        let ruleFormRef = ref(null)

        watch(() => visible.value, () => {
            if (visible.value) {
                choosable.value = editorForm.value.content ? JSON.parse(editorForm.value.content) : {A: '', B: '', C: '', D: ''}
            }
        })

        const methods = {
            BeforeClose () {
                visible.value = false
            },
            EditorSubmit () {
                const formEl = ruleFormRef.value;
                if (!formEl) return
                editorForm.value.content = JSON.stringify(choosable.value)
                formEl.validate((valid) => {
                    if (valid) {
                        let loading = elplus.loading()
                        if (editorForm.value.id) {
                            QuestionEdit({...editorForm.value}).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        } else {
                            QuestionAdd({...editorForm.value}).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        }
                    }
                })
            }
        }

        return {
            ruleFormRef,
            rulesForm,
            visible,
            editorForm,
            choosable,
            ...methods
        }
    },
}
</script>

<style lang="scss" scoped>
.choosable{
    width: 100%;
    .item {
        display: flex;
        margin-bottom: 10px;
        .el-input {
            flex: 1;
        }
    }
}
</style>