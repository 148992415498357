<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @operate-button-call-back="OperateButtonCallBack" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
        <AnswerEdit @submit="ShoppingEditSubmit"/>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import AnswerEdit from './module/answerEdit.vue'
import { provide, ref } from 'vue'
import { list } from '@/api/answer'
export default {
    components: {ATable, AnswerEdit},
    setup () {
        const tableUrl = ref(list)
        const columns = ref([
            {prop: 'title', label: '标题'},
            {prop: 'content', label: '内容', render (text) {
                let params = text ? JSON.parse(text) : {}
                let html = ''
                Object.keys(params).forEach(key => {
                    html += `<div>${key}：${params[key]}</div>`
                })
                return html
            }},
            {prop: 'result', label: '答案'},
            {prop: 'analysis', label: '解析'},
            {prop: 'operate', label: '操作', type: 'operate', width: 100, button: [{label: '编辑', dispose: 'Editor'}]},
        ])

        const searchFormItems = ref([
            {label: '标题', prop: 'title', type: 'input'},
        ])
        let editorForm = ref({})
        let answerVisible = ref(false)
        let table = ref(null)
        provide('answerVisible', answerVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                if (row.id) {
                    editorForm.value = {title: row.title, content: row.content, result: row.result, analysis: row.analysis, id: row.id}
                } else {
                    editorForm.value = {title: '', content: JSON.stringify({A: '', B: '', C: '', D: ''}), result: '', analysis: ''}
                }
                answerVisible.value = !answerVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            ShoppingEditSubmit () {
                answerVisible.value = false
                table.value.SearchSubmit()
            }
        }

        return {
            table,
            tableUrl,
            columns,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>